import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Header2 from "../components/Common/Header"
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import { GiChicken } from "react-icons/gi";
import { GiCorn } from "react-icons/gi";
import CookieIcon from '@mui/icons-material/Cookie';
import { FaCookieBite } from "react-icons/fa6";
import { BiSolidFoodMenu } from "react-icons/bi";
import { MdFastfood } from "react-icons/md";
import { RiEmojiStickerLine } from "react-icons/ri";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));
const HomePage = () => {
  const location = useLocation(); 
  const [sizex, setSizeX] = useState("16oz");
  const [sizePrice, setSizePrice] = useState(80)
  const [additionItemPrice, setadditionItemPrice] = useState(5);
  const [additionItem, setadditionItem] = useState(false)
  const [total, setTotal] = useState(0);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [unit_number, setunit_number] = useState("");
  const [postal_code, setpostal_code] = useState("");
  const [city, setCity] = useState("");
  const [addresserror, setAddresserror] = useState(false);
  const [unit_numbererror, setunit_numbererror] = useState(false);
  const [postal_codeerror, setpostal_codeerror] = useState(false);
  const [cityerror, setCityerror] = useState(false);
  const [pickup, setPickup] = useState(true);
  const [dishes, setDishes] = useState([]);
  const [weekly_menu_meals, set_weekly_menu_meals] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [meals, setMeals] = useState([]);
  const [turn_off_remove, set_turn_off_remove] = useState(false);
  const [turn_off_add, set_turn_off_add] = useState(false);
  const [not_successfull_substitution, set_not_successfull_substitution] = useState(false);
  const [nonveg, set_nonveg] = useState([]);
  const [veg, set_veg] = useState([]);
  const [additional_items, set_additional_items] = useState([]);
  const [disable_submit, set_disable_submit] = useState(true);
  const [taxes, setTaxes] = useState(0)



useEffect(()=>{
  calculateTotal()
},[additional_items, sizex, pickup])


  const calculateTotal = () => {
   
    if(sizex === "24oz"){
        let tempTotal = 75 + (pickup ? 0 : 5)
        
        if(additional_items && additional_items.length > 0){
          tempTotal = tempTotal + additional_items.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity * currentValue.price), 0) 
        }
        setTotal(tempTotal)
    }
    if(sizex === "16oz"){
        let tempTotal = 65 + (pickup ? 0 : 5)
        if(additional_items && additional_items.length > 0){
          tempTotal = tempTotal + additional_items.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity * currentValue.price), 0)
        }
        setTotal(tempTotal)
    }   
  }
useEffect(()=>{
  let no_submit = true 
  if(nonveg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0,)  >= 4 && veg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0) >=  2){
    no_submit = false
  }

  set_disable_submit(no_submit)
},[veg, nonveg])
  // useEffect(()=>{

  //   calculateTotal("16oz")
  // },[])

  useEffect(()=>{
    // fetch("http://localhost:4000/get_weekly_Menu")

    // fetch("https://admin.billfern.ca/get_weekly_Menu")
    fetch("https://dsqqdxoqddpdvzqosdmm.supabase.co/functions/v1/hello-world", {method: "POST"})
    .then((response)=>response.json())
    .then((data)=>{
      console.log(data)
      let temp = data.menu[0]
      set_weekly_menu_meals(temp)
      let temp_non_veg = [];
      let temp_veg = [];
      let temp_additional_items = [];
      Object.keys(temp).map((el)=>{
        let temp_obj = {quantity: 0}
        if(el.includes("nonveg")){
          temp_obj["name"] = temp[el]
          temp_non_veg.push(temp_obj)
        }
        if(el.startsWith("veg")){
          temp_obj["name"] = temp[el]
          temp_veg.push(temp_obj)
        }
        if(el.includes("additional_item")){
          if(typeof(temp[el])=== "string"){
            let t = {
              name : temp[el],
              price: temp[el + "_price"],
              quantity: 0
              // temp_obj["name"] = temp[el]
            }
            temp_additional_items.push(t)
          }
          // console.log(t)
        }
      })

      
      set_additional_items(temp_additional_items)

      temp_non_veg = temp_non_veg.map((el, i)=>{
        if(i <= 3){
          return {...el, "quantity": 1}
        } return el
      })

      temp_veg = temp_veg.map((el, i)=>{
        if(i <= 1){
          return {...el, "quantity": 1}
        } return el
      })
      set_nonveg([...temp_non_veg]);
      set_veg([...temp_veg])
      set_additional_items([...temp_additional_items])
      if(data && data.data && data.data.length === 0){
        setShowMenu(false)
        
      } else {
   let obj = data.menu
      let temp_meals = [
        {
          name: obj.name1,
          quantity: 1, 
          substituted: false,
          substituted_for: ""
        },
          {
          name: obj.name2,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },
          {
          name: obj.name3,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },
          {
          name: obj.name4,
          quantity: 1,
          substituted: false,
           substituted_for: ""
        },
          {
          name: obj.name5,
          quantity: 1,
          substituted: false,
           substituted_for: ""
        },
          {
          name: obj.name6,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },

      ]
        setMeals(temp_meals)
      }
   
      setadditionItemPrice(parseInt(data.data.additional_item_price))
      

  })
    .catch(e=>console.log(e))
  },[])



  const submitOrder = (e) =>{
  
    if(name === ""){
      setNameError("Please Enter your name")
      return
    }
    if(email === ""){
      setEmailError("Please Enter your email")
      return
    }
    if(phone === ""){
      setPhoneError("Please Enter your Phone number")
      return
    }
    if(pickup === false){
        if(address === ""){
            setAddresserror("Please Enter your address")
            return
          }
          if(postal_code === ""){
            setpostal_codeerror("Please Enter your Postal Code")
            return
          }
          if(city === ""){
            setCityerror("Please Enter your City")
            return
          }
    }


    if(name && phone && email ){
      setLoading(true)
      let data = {}
         data = {
            name: name,
            phone: phone,
            email: email,
            tax:  (total*taxes).toFixed(2) ,
            orderSize: sizex,
            pickup: pickup ,
            total: (parseFloat(total) + (total*taxes)).toFixed(2),
            nonveg: nonveg,
            veg: veg,
            additional_items: additional_items,
            address: address,
            unit_number: unit_number,
            postal_code: postal_code,
            city: city,
            subtotal: total.toFixed(2)
            
          }
   
     


      var canContinue = true
    // fetch("http://localhost:4000/sendweeklymenu",{

      fetch("https://dsqqdxoqddpdvzqosdmm.supabase.co/functions/v1/send-email", {
            method: 'POST',
            // headers: {
            //  'Content-Type': 'application/json',
            //  "Access-Control-Origin": "*",
            //  'Access-Control-Allow-Origin': "*"
            // },
            body: JSON.stringify(data)
        })
      .then(response=>response.json())
      .then((data)=>{
        setLoading(false)
        setSuccess(true)
    

      
        
      }).catch(e=> {setLoading(false); alert(e)})
    // if(canContinue){

    //   fetch("https://admin.billfern.ca/sendOrder",{
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
      
    //   } ).then(response=>response.json()).then((data)=>
      
    //   {
    //     if(data.data === "success"){
    //           setLoading(false)
    //         setName("") 
    //         setEmail ("") 
    //         setPhone("") 
    //         setSuccess(true)
      
          
      
            
    //   }}
      
    //   ).catch((e)=>{console.log(e);setSuccess(false);  setLoading(false)})
    // }
    }
  }

  const increase_count = (i) =>{  
    if(meals[i].quantity <2){
     
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity + 1
      setMeals([...temp_meals])
    
    
    } else {
      return 
    }

  }

  const substitute_meal = (mealName) =>{
    set_not_successfull_substitution(false)
    let already_substitution_made_index = meals.findIndex((el)=>(el.substituted_for === weekly_menu_meals.substituted_item))
    let temp_meals = meals
    if(!mealName){
        setMeals(meals.map((el)=>{
          return {...el, "substituted_for": ""}
        }))
    } else {
     
      if(already_substitution_made_index > -1){
       
        temp_meals[already_substitution_made_index].substituted_for = ""
        setMeals([...temp_meals])
        
      }
      let temp_meals_index = meals.findIndex((el)=> el.name === mealName)
      if(temp_meals_index > -1){
        temp_meals[temp_meals_index].substituted_for = weekly_menu_meals.substituted_item
        setMeals([...temp_meals])
        if( temp_meals[temp_meals_index].quantity <1){
          set_not_successfull_substitution(true)
        }
  
      }
    }
   

  }
  useEffect(()=>{
    let total_items = meals.reduce((a,b)=> a + b.quantity, 0)

    if(total_items > 6){
      set_turn_off_add(true);
      set_turn_off_remove(false);
    } else if(total_items === 6) {
      set_turn_off_remove(false);
        set_turn_off_add(true);

    } else {
      
      set_turn_off_add(false);
      set_turn_off_remove(true);
    }

  },[meals])


  useEffect(()=>{

  },[veg, nonveg])

  const decrease_count = (i) =>{  
    if(meals[i].quantity){
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity - 1
      setMeals([...temp_meals])
    
    } else {
      return 
    }

  }



  return  (
    <>
    <Helmet >
<title>Nostalgia Kitchen - Weekly Menu</title>
<link rel="notImportant" href="https://www.nostalgiakitchen.ca/weeklymenu" />
<meta name="description"   content="View Our Delicious Goan Menu Weekly" />
<link rel="canonical" href="https://www.nostalgiakitchen.ca/weeklymenu" />
<meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.nostalgiakitchen.ca/weeklymenu"/>

</Helmet> 
   
  <Box sx={{ width: '100%'}}> 
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    <Grid item xs={12}>
          <Item sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                    <Header2 />
                    {/* <Header /> */}
                 
                  </Item>
                  <Item sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Header />
                 
                  </Item>
          {/* <Item>
            <Header /> 
            <Header2 />
          </Item> */}
        </Grid>
         <Grid item xs={12} style={{margin:"5px"}}>
                <div style={{display: !showMenu ? "none" : ""}}>
                    <div>
                    <div style={{display:"flex", justifyContent:"center"}}> 
                        <h1>Nostalgia's Weekly Menu now available online</h1> <br />
                    </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <p>
                                Order six delicious dishes, either 16oz or enjoy more of your favourites and get 24oz containers.
                                The food is delivered cool and needs to be warmed up.<br />
                                 <b>Please place order by {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : moment(weekly_menu_meals.place_order_by).format("DD MMMM YYYY")} </b> <br />
                                Delivery Date <b>{!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : moment(weekly_menu_meals.delivery_date).format("DD MMMM YYYY")}</b>
                            </p>
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <b>The six dishes for this week are:</b> */}
                         </div>
                         <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                {meals.reduce((a,b)=> a + b.quantity, 0) < 6 && <b style={{color:"#8B0000"}}>** Please add another Dish
                                <br /> **Minimum of 6 items are required. You only have {meals.reduce((a,b)=> a + b.quantity, 0)} items.
                                </b>}
                                {meals.reduce((a,b)=> a + b.quantity, 0) < 6 && <b style={{color:"#8B0000"}}></b>}
                              
                              </div>
                         </div>
                          <div  style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                  <Paper elevation={5} style={{width:"90%", maxWidth:"500px", padding:"10px"}}>
                                      <div style={{width:"100%", textAlign:"center", backgroundColor:"#ffc109", color:"black"}}>Step 1: Pick <b style={{fontSize:"1.4em"}}>4</b> non-veg Dishes</div>
                                      <div style={{marginBottom:"10px", borderBottom:"1px dashed black", textAlign:"center", fontSize:"1.2em", padding:"10px"}}>
                                        <GiChicken size={30}/> 
                                        <b>Non-Vegetarian</b>
                                        <br />
                                        {(4 - nonveg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0)) <= 0   ? "" : 
                                        <div style={{color:"darkred"}}>
                                              <RiEmojiStickerLine 
                                                  style={{color:"crimson"}}
                                                  size={50}
                                              /> 
                                              Dont Miss Out, Please add {" "}
                                              <b style={{fontSize:"1.2em"}}>
                                                {4 - nonveg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0,) } </b> more <GiChicken size={30}/> 
                                                 dish.
                                        </div>
                                      }
                                       </div>
                                     <table style={{width:"100%"}}>
                                          <tbody style={{width:'100%'}}>
                                              {nonveg && nonveg.map((el, i)=>(
                                                <tr style={{fontSize:"1.3", padding:"10px"}}>
                                                  <td>{el.name}</td>
                                                  <td>
                                                        {
                                                        <button 
                                                          style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                            size="small"  variant="outlined"  
                                                            onClick={()=>{
                                                              let temp = nonveg;
                                                              if(temp[i].quantity){
                                                                temp[i].quantity = temp[i].quantity - 1
                                                                set_nonveg([...temp]) 
                                                              }
                                                            }}
                                                            disabled={( (el.quantity > 0)) ? false : true }

                                                        >
                                                          -
                                                        </button>
                                                        
                                                        } 
                                                     
                                                  </td> 
                                                  <td>{el.quantity}</td> 
                                                  <td>

                                                  { 
                                                            <button 
                                                            style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                              disabled={nonveg.reduce((a,b)=> a + b.quantity, 0) < 4 ?  false : true }
                                                              size="small"  
                                                              variant="outlined"  
                                                              onClick={()=>{
                                                                let temp = nonveg;
                                                                temp[i].quantity = temp[i].quantity + 1
                                                                set_nonveg([...temp]) 
                                                            }}
                                                            >
                                                              +
                                                            </button>
                                                            } 
                                                  </td>
                                                      
                                                </tr>))}
                                            
                                          </tbody> 

                                     </table>
                                     <div style={{width:"100%", textAlign:"center", backgroundColor:"#ffc109", color:"black"}}>Step 2: Pick <b style={{fontSize:"1.4em"}}>2</b> Veg Dishes</div>
                                      <div style={{marginBottom:"10px", borderBottom:"1px dashed black", textAlign:"center", fontSize:"1.2em", padding:"10px"}}><GiCorn size={30}/> 
                                      <b>Vegetarian</b>
                                       <br />
                                          {(2 - veg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0)) <= 0   ? "" : 
                                            <div style={{color:"darkred"}}>
                                                  <RiEmojiStickerLine 
                                                      style={{color:"crimson"}}
                                                      size={50}
                                                  /> 
                                                  Dont Miss Out, Please add {" "}
                                                  <b style={{fontSize:"1.2em"}}>
                                                    {2 - veg.reduce((accumulator, currentValue) => accumulator + currentValue.quantity,0,) } </b> more <GiCorn size={30}/> 
                                                    dish.
                                            </div>
                                          }
                                       
                                       </div>
                                     <table style={{width:"100%"}}>
                                          <tbody style={{width:'100%'}}>
                                              {veg && veg.map((el, i)=>(
                                                <tr style={{fontSize:"1.3", padding:"10px"}}>
                                                  <td>{el.name}</td>
                                                  <td>
                                                        {
                                                        <button 
                                                          style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                            size="small"  variant="outlined"  
                                                            onClick={()=>{
                                                              let temp = veg;
                                                              if(temp[i].quantity){
                                                                temp[i].quantity = temp[i].quantity - 1
                                                                set_veg([...temp]) 
                                                              }
                                                            }}
                                                            disabled={( (el.quantity > 0)) ? false : true }

                                                        >
                                                          -
                                                        </button>
                                                        
                                                        } 
                                                     
                                                  </td> 
                                                  <td>{el.quantity}</td> 
                                                  <td>

                                                  { 
                                                            <button style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                              disabled={veg.reduce((a,b)=> a + b.quantity, 0) < 2 ?  false : true }
                                                              size="small"  
                                                              variant="outlined"  
                                                              onClick={()=>{
                                                                let temp = veg;
                                                                temp[i].quantity = temp[i].quantity + 1
                                                                set_veg([...temp]) 
                                                            }}
                                                            >
                                                              +
                                                            </button>
                                                            } 
                                                  </td>
                                                      
                                                </tr>))}
                                            
                                          </tbody> 

                                     </table>
                                        {/* 12-September-2023 -BF Deploy once server is available */}
                    <div style={{width:"100%", textAlign:"center", backgroundColor:"#ffc109", color:"black"}}>Step 3: Select <b style={{fontSize:"1.4em"}}>Size</b></div>
                    <div style={{display:"flex", justifyContent:"center", marginTop:"10px", }}>
                                    <input type="radio" 
                                            id="16oz"
                                            name="size"
                                            value="16oz" 
                                                onChange={(event) =>  setSizeX(event.target.value)} inline
                                                checked={sizex === "16oz"} 
                                                />
                              
                                    <label htmlFor="16oz">16oz</label>{"  "}
                                    
                              <input type="radio" 
                                            id="24oz"
                                            style={{marginLeft:"10px"}}
                                            name="size"
                                            value="24oz" 
                                            onChange={(event) =>  setSizeX(event.target.value)}  inline
                                            checked={sizex === "24oz"} 
                                            />
                              
                                        <label htmlFor="24oz">24oz</label>
                                      
                                        
                    </div>
                                     <div style={{display:"flex", justifyContent:"center"}}>
                                          <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                                            
                                              <b>  
                                                <MdFastfood 
                                                size={50}
                                                style={{color:"#aa7733", transform:"rotate3d(1, 1, 1, 23deg)", verticalAlign:"baseline"}}
                                              /> Add More ?</b>
                                              <div style={{margin:"15px", width:"100%"}}>
                                                <table style={{width:"100%"}}>
                                                  <tbody>
                                                  {additional_items.map((el, i)=>(
                                                       <tr style={{fontSize:"1.3", padding:"10px", borderBottom:"1px solid black"}}>
                                                       <td>{el.name} for ${el.price}</td>
                                                       <td>
                                                             {
                                                             <button 
                                                               style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                                 size="small"  variant="outlined"  
                                                                 onClick={()=>{
                                                                   let temp = additional_items;
                                                                   if(temp[i].quantity){
                                                                     temp[i].quantity = temp[i].quantity - 1
                                                                     set_additional_items([...temp]) 
                                                                   }
                                                                 }}
                                                                 disabled={( (el.quantity > 0)) ? false : true }
     
                                                             >
                                                               -
                                                             </button>
                                                             
                                                             } 
                                                          
                                                       </td> 
                                                       <td>{el.quantity}</td> 
                                                       <td>
     
                                                       { 
                                                                 <button style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                                   size="small"  
                                                                   variant="outlined"  
                                                                   onClick={()=>{
                                                                     let temp = additional_items;
                                                                     if(temp[i]?.name.includes("Complimentary") && temp[i].quantity === 1){
                                                                        return
                                                                     }
                                                                     temp[i].quantity = temp[i].quantity + 1
                                                                     set_additional_items([...temp]) 
                                                                 }}
                                                                 >
                                                                   +
                                                                 </button>
                                                                 } 
                                                       </td>
                                                       <td>${(el.price * el.quantity).toFixed(2)}</td>
                                                           
                                                     </tr>))}
                                                    
                                                  </tbody>
                                                   

                                                </table>
                                              </div>
                                          </div>

                                     </div>
                    <div 
                    style={{
                      margin:"10px",
                      fontSize:"1.5em",
                      fontWeight:"bold",
                      borderBottom:"2px solid #ffc107"
                    }}>
                      Delivery/Pick up Info
                    </div>
                 
                     <Stack spacing={2}>
                            <div style={{ display:"flex", justifyContent:"center"}}>

                            <div style={{maxWidth:"50%", display:"flex", flexDirection:"column", gap:"5px"}}>
                                <TextField  size="small" id="standard-basic" label="Name" variant="outlined" error={nameError}  value={name} onChange={(e)=>{setName(e.target.value); setNameError("")}}/>     
                                <span style={{color:"red"}}>{nameError}</span>
                                <TextField size="small" id="standard-basic" label="Email" variant="outlined" error={emailError} value={email} onChange={(e)=>{setEmail(e.target.value); setEmailError("")}}/>   
                                <span style={{color:"red"}}>{emailError}</span>
                                <TextField size="small" id="standard-basic" label="Phone Number" error={phoneError} value={phone} variant="outlined" onChange={(e)=>{setPhone(e.target.value); setPhoneError("")}}/>    
                                <span style={{color:"red"}}>{phoneError}</span>
                            </div>

                            </div>
                          
                            <Box sx={{ minWidth: 200 }}>
                            <div style={{display:"flex", justifyContent:"center", margin:"5px"}}>
                                            <input type="radio" 
                                                    id="pickup"
                                                    name="typeofdelvery"
                                                    value="16oz" 
                                                        onChange={(event) => setPickup(true)} inline
                                                        checked={pickup === true} 
                                                        />
                                                        
                                            <label htmlFor="pickup">Pick up in store</label>
                                            
                                                <input type="radio" 
                                                id="delivery"

                                                name="typeofdelvery"
                                                value="delivery" 
                                                    onChange={(event) => setPickup(false)} inline
                                                    checked={pickup === false} 
                                                    />
                                                        
                                                <label htmlFor="delivery">Delivery</label>
                                                
                                            </div>
                                            <div style={{display:"flex", justifyContent:"center",  margin:"5px"}}>
                                                {pickup === false && 
                                                <div style={{display:"flex", justifyContent:"center", flexDirection:"column", gap:"5px"}}>
                                                <TextField size="small" id="standard-basic" label="Address*" error={addresserror} value={address} variant="outlined" onChange={(e)=>{setAddress(e.target.value); setAddresserror("")}}/>    
                                                    <span style={{color:"darkred"}}>{addresserror}</span>
                                               
                                                <TextField size="small" id="standard-basic" label="Unit number" error={unit_numbererror} value={unit_number} variant="outlined" onChange={(e)=>{setunit_number(e.target.value); setunit_numbererror("")}}/>    
                                                
                                                <TextField size="small" id="standard-basic" label="Postal Code*" error={postal_codeerror} value={postal_code} variant="outlined" onChange={(e)=>{setpostal_code(e.target.value); setpostal_codeerror("")}}/>    
                                            <span style={{color:"darkred"}}>{postal_codeerror}</span>
                                                
                                                <TextField size="small" id="standard-basic" label="City*" error={cityerror} value={city} variant="outlined" onChange={(e)=>{setCity(e.target.value); setCityerror("")}}/>    
                                                <span style={{color:"darkred"}}>{cityerror}</span>
                                    
                                    </div>
                                                }
                                            </div>
                    
                        </Box>
                    </Stack> 
      <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{borderTop:"1px black dashed", borderBottom:"1px black dashed", padding:"5px"}}>
                            
                                Sub-Total: ${total.toFixed(2)} <br />
                                {pickup === false && <s>"Delivery Charge: $6"</s>}
                                {pickup === false && <br />}
                                tax: ${(parseFloat(total) *taxes).toFixed(2)}
                                 <br />
                               <b>Total: ${(parseFloat(total)  + ((parseFloat(total) *taxes))).toFixed(2) }</b> <br />
                                   {/*<Button style={{
                                     backgroundColor:"#8B0000",
                                     color:"white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px"
                                }}
                                onClick={ ()=>w
                                // onClick={ ()=>submitOrder()}indow.open('tel:647-863-9249')}

                                >Call 647-863-9249 to place order</Button> */}
                            </div>
                         </div> 
                <div style={{display:'flex', justifyContent:'center'}}>
                      {/* 12-September-2023 -BF Deploy once server is available */}
          {loading ? <CircularProgress style={{color:"#8B0000"}}/> : 
                <Button style={{
                                     backgroundColor: disable_submit ? "gray" : "#8B0000",
                                     color: "white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px",
                                     display: success ? "none" : ""
                                    }}
                                    disabled={success || disable_submit}
                                // onClick={ ()=>window.open('tel:647-863-9249')}
                                onClick={ ()=>submitOrder()}

                                >Submit Order</Button>} 
             </div>
             <div style={{display:"flex", justifyContent:"center"}}>
                        {success && "Your Order was placed successfully. Please wait for a confirmation call. "}
             </div>
 
                                  </Paper>

                          </div>



                        <div style={{display:"flex", justifyContent:"center"}}>
                        {!weekly_menu_meals && <CircularProgress style={{color:"#8B0000"}}/> }
                        
                        </div>
                
                      
                       



                    </div>

                </div>
        </Grid>
        <div style={{display: showMenu ? "none" :'flex', justifyContent:"center", textAlign:"center", width:"100%", padding:"20px"}}>
                                    <b>Unfortunately, this Menu is no longer available, please wait for next week's menu or contact nostalgia to check for availability.</b>
        </div>
       
    <Footer />
    </Grid>   
  </Box>
  </>
  )
};

export default HomePage;
